import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { CartContext } from '../Cart/CartProvider';

import './ProductThumbnail.scss';

const ProductThumbnail = ({ product }) => {
  const { add, toggle } = useContext(CartContext);

  return (
    <div key={product.id} className="product">
      <div className="product__image">
        { product.image &&
          <img src={product.image} alt={product.name} />
        }
      </div>
      <div className="product__meta">
        <h2>{ product.name }</h2>
        <h3>{`€ ${product.unit_amount / 100}`}</h3>
        <p>{ product.description }</p>
      </div>
      <div className="product__button">
        <button
          className="checkout-button btn btn--primary"
          onClick={() => { add(product.priceId); toggle(true); }}>Add to cart</button>
      </div>
    </div>
  );
}

ProductThumbnail.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductThumbnail;
