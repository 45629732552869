import React from 'react';
import StoreLayout from '../components/StoreLayout/StoreLayout';
import SEO from '../components/SEO/SEO';
import Newsletter from '../components/Newsletter/Newsletter';
import { graphql } from 'gatsby';
import ProductThumbnail from '../components/Products/ProductThumbnail';

import '../components/Products/Products.scss';

const Products = ({ pageContext, data }) => {
    const { allProductsJson: products } = data;
    const { title, poster } = pageContext;

    return (
        <StoreLayout page={title.toLowerCase().replace(' ', '')} poster={poster}>
            <SEO
              title={ title }
              description="Everything about Wibi Soerjadi, his history, life path and his amazing sheet music is available right here."
              keywords={[`wibi`, `soerjadi`, `wibi soerjadi`, `about`, `masterclass`, `summercourse`, `academy`, `sheet`, `music`, `sheet music`, `shop`]}
            />
            <main className="main">
                <div className="wrapper">
                    <h1 className="main-title">Sheet Music</h1>
                    <div className="products">
                        <div className="product">
                            <div className="product__image">
                                <img src="/img/formula-1-theme@2x.jpg" alt="Formula 1 Theme" />
                            </div>
                            <div className="product__meta">
                                <h2>Formula 1 Theme</h2>
                                <h3>€ 10,40</h3>
                                <p>Transcription for piano solo by Wibi Soerjadi.</p>
                            </div>
                            <div className="product__button">
                                <a className="checkout-button btn btn--primary" target="_blank" href="https://www.musicnotes.com/sheetmusic/mtd.asp?ppn=MN0257525">Visit MusicNotes</a>
                            </div>
                        </div>
                        <div className="product">
                            <div className="product__image">
                                <img src="/img/home-alone@2x.jpg" alt="Home Alone" />
                            </div>
                            <div className="product__meta">
                                <h2>Home Alone</h2>
                                <h3>€ 7,94</h3>
                                <p>‘Somewhere in my memory’ Transcription for piano solo.</p>
                            </div>
                            <div className="product__button">
                                <a className="checkout-button btn btn--primary" target="_blank" href="https://www.musicnotes.com/sheetmusic/mtd.asp?ppn=MN0248638">Visit MusicNotes</a>
                            </div>
                        </div>
                        {products && products.edges.map((productNode) => {
                            const { node: product } = productNode;

                            return (
                                <ProductThumbnail key={product.id} product={product} />
                            )
                        })}
                    </div>
                </div>
            </main>
            <Newsletter />
        </StoreLayout>
    )
};

export const query = graphql`
    query ProductsQuery {
        allProductsJson {
            edges {
                node {
                    id
                    image
                    name
                    description
                    unit_amount
                    priceId
                }
            }
        }
    }
`

export default Products;